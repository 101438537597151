
.side-bar {
    background-color: aliceblue;
    height: 100vh;
    border-right: #234523;
}
.sidebar-item {
    padding: 1rem;
    font-size: 1.1rem;
    text-decoration: none;
    color: black;
}
.sidebar-item:hover {
    background: #343a40;
    color: white;
}