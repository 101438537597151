@keyframes checkmark {
    0% {
        stroke-dashoffset: 50px;
    }
    100% {
        stroke-dashoffset: 0;
    }
}

.checkmark-circle {
    stroke-dasharray: 166px;
    stroke-dashoffset: 166px;
    animation: dash 0.6s linear forwards;
}

.checkmark-check {
    transform-origin: 50% 50%;
    stroke-dasharray: 50px;
    stroke-dashoffset: 50px;
    animation: checkmark 0.4s 0.4s ease-in-out forwards;
}

.checkmark-svg {
    width: 50px;
    height: 50px;
    display: block;
    margin: 20px auto;
    fill: none;
    stroke: green;
    stroke-width: 3.5;
}
